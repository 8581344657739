@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'ngx-toastr/toastr';

body {
  margin: 0;
  color: theme('colors.background');
  color: var(--charcoal);
  font-family: 'Poppins', sans-serif;
}
html {
  scroll-behavior: smooth;
}
input,
select {
  &:focus {
    border-color: theme('colors.sunflowerGold') !important;
    outline: unset;
  }

  &::placeholder {
    color: theme('colors.slateGray');
    font-size: 14px;
    font-weight: 400;
  }
}

.tooltip {
  font-size: 12px;
}

.ngx-toastr {
  width: fit-content !important;
  display: flex;
  justify-content: center;
}

.subtitle h1 {
  font-size: 40px;
  font-weight: 600;
  background-image: linear-gradient(to left, #7e7e7e, #262626);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.imgHeight35 {
  height: 35px;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eaeaea;
  border-radius: 100px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(185, 185, 185);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(142, 142, 142);
}

.btn {
  background-color: #ffd68b;

  &:hover {
    background-color: #fbc96d;
  }
}

.steelBlue {
  background-color: theme('colors.steelBlue');
}

.mainButton {
  background-color: #ffc65d;
  border-radius: 8px;
  border: 1px solid #ffc955;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  padding: 12px;
}

.mainButton:hover,
.mainButton:focus {
  background-color: #ffcc5d;
}

/* poppins-100 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('assets/fonts/poppins-v20-latin-100.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-100.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-100.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-100.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-100.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-100.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-100italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('assets/fonts/poppins-v20-latin-100italic.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-100italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-100italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-100italic.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-100italic.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-100italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('assets/fonts/poppins-v20-latin-200.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-200.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-200.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-200.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-200.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-200.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-200italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('assets/fonts/poppins-v20-latin-200italic.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-200italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-200italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-200italic.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-200italic.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-200italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/poppins-v20-latin-300.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-300.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-300.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-300.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-300.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('assets/fonts/poppins-v20-latin-300italic.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-300italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-300italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-300italic.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-300italic.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-300italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/poppins-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-regular.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-regular.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-regular.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url('assets/fonts/poppins-v20-latin-italic.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-italic.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-italic.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/poppins-v20-latin-500.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-500.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-500.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-500.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('assets/fonts/poppins-v20-latin-500italic.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-500italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-500italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-500italic.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-500italic.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-500italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/poppins-v20-latin-600.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-600.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-600.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-600.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('assets/fonts/poppins-v20-latin-600italic.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-600italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-600italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-600italic.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-600italic.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-600italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/poppins-v20-latin-700.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-700.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-700.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-700.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('assets/fonts/poppins-v20-latin-700italic.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-700italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-700italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-700italic.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-700italic.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-700italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('assets/fonts/poppins-v20-latin-800.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-800.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-800.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-800.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-800.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('assets/fonts/poppins-v20-latin-800italic.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-800italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-800italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-800italic.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-800italic.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-800italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-900 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('assets/fonts/poppins-v20-latin-900.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-900.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-900.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-900.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-900.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-900italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('assets/fonts/poppins-v20-latin-900italic.eot'); /* IE9 Compat Modes */
  src:
    url('assets/fonts/poppins-v20-latin-900italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('assets/fonts/poppins-v20-latin-900italic.woff2') format('woff2'),
    /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ url('assets/fonts/poppins-v20-latin-900italic.woff')
      format('woff'),
    /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */ url('assets/fonts/poppins-v20-latin-900italic.ttf')
      format('truetype'),
    /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
      url('assets/fonts/poppins-v20-latin-900italic.svg#Poppins') format('svg'); /* Legacy iOS */
}
